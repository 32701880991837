<script setup lang="ts">
import type { HouseDetailsAttributes } from 'lc-services/types'
import type { Color } from '../BaseForm/types'

const { color = 'secondary' } = defineProps<{
  color?: Color
  house?: HouseDetailsAttributes
  source?: string
  withIcon?: boolean | number
  withBorder?: boolean
}>()

const { openScheduler } = useMeetingScheduler()
defineSlots<{ default(): any }>()
</script>

<template>
  <BaseButton
    :class="['w-fit', { '!border-none': !withBorder }]"
    :color="color"
    type="button"
    @click="openScheduler(source, house)"
  >
    <div class="flex items-center gap-2 text-md sm:gap-4 sm:text-base">
      <BaseIcon
        v-if="withIcon"
        class="flex-none"
        name="phone"
        :size="typeof withIcon === 'number' ? withIcon : 1.5"
      />
      <slot>
        <span>{{ $t('global.scheduleACall') }}</span>
      </slot>
    </div>
  </BaseButton>
</template>
